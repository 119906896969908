
import { Component, Vue } from 'vue-property-decorator'
import { apiRegionAgentAdd, apiRegionAgentTypeList, apiRegionList } from '@/api/finance'

@Component({
  components: {}
})
export default class RegionAgentAdd extends Vue {
  /** S Data **/

  // 添加商城表单数据
  form: any = {
    name: '', // 名称
    mobile: '', // 手机号
    note: '', // 备注
    province: '', // 省ID
    city: '', // 市ID
    district: '', // 区县ID
    type: '1' // 类型 1区域代理 2市代理 3省代理 4合伙人 5云智
  };

  provinceList: any = [];
  cityList: any = [];
  districtList: any = [];

  otherLists: any = {
    type_lists: []
  };

  /** E Data **/

  /** S Methods **/

  async onSubmit () : Promise<void> {
    await apiRegionAgentAdd(this.form)
    setTimeout(() => this.$router.go(-1), 500)
  }

  proviceChange (pid = null) {
    this.form.city = ''
    this.form.district = ''
    this.cityList = []
    this.districtList = []
    if (pid !== null || pid !== '') {
      apiRegionList({ pid: pid }).then((res: any) => {
        this.cityList = res
      })
    }
  }

  cityChange (pid = null) {
    this.form.district = ''
    this.districtList = []
    if (pid !== null || pid !== '') {
      apiRegionList({ pid: pid }).then((res: any) => {
        this.districtList = res
      })
    }
  }

  getRegionAgentTypeList () {
    apiRegionAgentTypeList().then(res => {
      this.otherLists = res
    })
  }

  getRegionList (pid = 0) {
    apiRegionList({ pid: pid }).then((res: any) => {
      this.provinceList = res
    })
  }

  /** E Methods **/

  /** S Life Cycle **/

  created () {
    this.getRegionAgentTypeList()
    this.getRegionList()
  }
  /** E Life Cycle **/
}
